$report-dark-bg: #434343;
$report-header-bg: #d9d9d9;
$report-holder-bg: #eeeeee;

.bg-white {
  background: white;
}

.no-padding {
  padding: 0 !important;
}


#entertainmentRankings > .report-card-body,
#guestExperienceRankings > .report-card-body {
  overflow-x:auto;
}


@media (min-width:1200px) {
  .report-card.category-element-chart {

    .report-card-body {
      .category-element-chart-data {
        height:224px;
        line-height:209px;
        overflow-y: auto;
      }
    }

    .category-element-chart-overall {
      -ms-flex: 0 0 150px;
      flex: 0 0 150px;

      border-right: 2px solid #999999;
    }
  }

}

@media (print) {
  .report-card.category-element-chart {

    .report-card-body {
      .category-element-chart-data {        
        overflow-y: hidden;
      }
    }
    
  }

}


.scorecard-row{
  // max-width: 100%;
  overflow-x: auto;
  margin-right:0 !important;
  margin-left:0 !important;
}
 

@media (max-width:575px) {

  .scorecard-container {

    .scorecard-team-logo {
      text-align:center !important;
    }

    .scorecard-evolved-logo {
      text-align:center !important;
    }
  }



  
   .scorecard-row > div.col-12 {
     padding:0 !important;
   }
  
   .row.report-background.report-holder {
     margin-left:0;
     margin-right:0;

   }

   .row {
    > .col-sm-1,
    > .col-sm-2,
    > .col-sm-3,
    > .col-sm-4,
    > .col-sm-5,
    > .col-sm-6,
    > .col-sm-7,
    > .col-sm-8,
    > .col-sm-9,
    > .col-sm-10,
    > .col-sm-11,
    > .col-sm-12
    {
     padding-left:0;
     padding-right:0;
    }
   }   
    .row.scorecard-quick-stats {
      margin-right:0 !important;
    }

  #entertainmentSummary,
  #guestExperienceSummary,
  #overallChart {

    .report-card-body, .report-card-body > div {
      height:272px !important;
    }
  }


}

.scorecard-container {
  // border: 1px solid #e1e1e1;
  //padding: 10px 20px;
  //width: 1270px;

  .scorecard-team-logo {
    text-align:left;
  }

  .scorecard-evolved-logo {
    text-align:right;
  }

  tbody {
    font-size: 16px;
  }

  .scorecard-center-title {
    margin-top: 10px;
  }

  .scorecard-center-title2 {
    margin-top: 10px;
    font-size: 18px;
    color: #C8102E;
    font-weight: bold;
  }

  .notes-header {
    margin-top: 10px;
    font-size: 18px;
    color: black;
    font-weight: bold;
  }

  .imageUpload {
    width: 250px;
    height: 250px;
  }

  .team-logo {
    max-height: 95px;
    max-width: 100%;
  }

  .scorecard-quick-stats {
    background: #dfdfdf;
    padding: 3px 0;
    margin-top: 10px;

    span>b {
      margin-right: 5px;
    }
  }

  .report-holder {
    padding: 0 10px;
  }

  .report-background {
    background: $report-holder-bg;
  }

  .dark-report-bg {
    background: $report-dark-bg;
    border-radius: .25rem;
  }

  .light-report-bg {
    background: white;
    color: black;
  }

  .nps-score {
    color: white;
    font-weight: bold;
    font-size: 80px;
    max-height: 115px;
  }

  .category-score {
    color: black;
    font-weight: bold;
    font-size: 40px;
    max-height: 115px;
  }

  .nps-description {
    font-weight: bold;
    color: white;
  }

  .report-card-sub-header {
    padding: 5px;
    background-color: #474747;
    color: white;
    font-weight: normal;
    border-radius:0 !important;

    &:hover {
      background-color: #474747 !important;
    }

    th {
      border-color:#575757 !important;
      color:white !important;
      border-radius: 0 !important;
      padding:4px 8px !important;
    }

  }

  .report-card {
    margin: 10px 0;
    // border:1px solid rgba(0,0,0,.13);
    box-shadow:0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) ;
    border-radius:.25rem;
    // border: 3px solid black;

    .row.no-gutters .report-card {
      margin:10px;
    }

    .ant-table-tbody>tr>td {
      padding: 3.4px;
    }

    .ant-table-thead>tr>th {
      padding: 3.4px;
    }

    .report-card-header {
      background: #4D95DD; //#ced9e5;
      font-size: 16px;
      color: white;
      padding: 5px;
      font-weight: bold;
      //border-bottom: 3px solid black;
      border-radius: .25rem .25rem 0 0 !important;
    }

    .report-card-header-alt {
      background: #C8102E; //#ced9e5;
      font-size: 16px;
      color: white;
      padding: 5px;
      font-weight: bold;
      //border-bottom: 3px solid black;
      border-radius: .25rem .25rem 0 0 !important;
    }

    .report-card-body {
      background: white;
      border-radius:.25rem;

      &.dark-report-bg {
        background: $report-dark-bg;
      }

      div {
        border-radius:.25rem;
      }
    }

    .report-card-header-alt + .report-card-body,
    .report-card-header + .report-card-body {
      border-radius: 0 0 .25rem .25rem ;
      // border-top:1px solid rgba(0,0,0,.13);

      div {
        border-radius: 0 0 .25rem .25rem ;
      }
    }
  }
}

.table-header-rotated {
  border-collapse: collapse;

  .csstransforms & td {
    width: 30px;
  }

  .no-csstransforms & th {
    padding: 5px 10px;
  }

  td {
    text-align: center;
    padding: 10px 5px;
    border: 1px solid #ccc;
  }

  td.left {
    text-align: center;
  }

  th.rotate {
    height: 170px;

    white-space: nowrap;
    text-align: center;

    // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate
    >.ant-table-header-column>div {
      transform: translate(-33%, 0%) rotate(-90deg);
      width: 170px;
    }

    .ant-table-column-title > div {
      overflow:hidden;
      text-overflow:ellipsis;
      padding:4px;
    }
  }

  th.row-header {
    padding: 0 10px;
  }
}

.th-border-thick {
  border: 2px solid dimgray !important;
}

.th-border-thin {
  border: 1px solid dimgray
}

.white-title {
  font-weight: bold;
  color: white;

  .ant-table-column-title {
    color: white !important;
  }
}

.header-alternate-bg {
  background: #f3f3f3 !important;
}

.demographics-col {
  min-width: 70px;
  max-width: 70px;
}

.demographics-table {
  table tbody {
    color: black !important;
  }
}

.demographics-section-border {
  //border-left: 1px solid black;
  border-right: 2px solid black !important;
}

.demographic-category {
  background-color: #f3f3f3;

  .ant-table-row-expand-icon {
    background-color: #f3f3f3;
  }
}



.thick-border-top{
  border-top: 2px solid black;
}

.thick-border-left{
  border-left: 2px solid black;
}

.thick-border-right{
  border-right: 2px solid black;
}

.thick-border-bottom{
  border-bottom: 2px solid black;
}

.demographics-cube {
  display: table-cell;
  padding: 8px;
  vertical-align: inherit;
  text-align: center;
  border: 1px solid #ccc;
  border-bottom: 1px solid #edf2f9;

  &.red-cell {
    background: #ff0000;
  }

  &.red-text-cell {
    color: #ff0000 !important;
    font-weight: bold;

  }

  &.yellow-cell {
    background: #ff0;
  }

  &.yellow-text-cell {
    font-weight: bold;
    color: #DAA520 !important;
  }

  &.green-cell {
    background: #0f0;
  }

  &.green-text-cell {
    color: #006400 !important;
    font-weight: bold;
  }

  &.black-cell {
    color: white !important;
    background: #434343;
  }

  &.grey-cell {
    background: #f3f3f3 !important;
  }
}

.demographics-empty-cell {
  background: #A9A9A9 !important;
}

//Scores
.low-score {
  color: red;
}

.medium-score {
  color: orange;
}

.high-score {
  color: black;
}

//MWL GUAGE
mwl-gauge {
  width: 150px;
  height: 150px;
  display: block;
  padding: 10px;
}

mwl-gauge>.gauge>.dial {
  stroke: #eee;
  stroke-width: 10;
  fill: rgba(0, 0, 0, 0);
}

mwl-gauge>.gauge>.value {
  stroke: rgb(255, 47, 47);
  stroke-width: 4;
  fill: rgba(0, 0, 0, 0);
  stroke-dasharray: 25 1;
}

mwl-gauge>.gauge>.value-text {
  fill: rgb(255, 47, 47);
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.5em;
}

.blue-table-row {
  background: #073763;
  color: white;

  &:hover {
    td {
      background: #073763 !important;
      color: white !important;
    }
  }
}

.demographic-category:hover {
  color:grey !important;
}


@media print {
  div {
    page-break-inside: avoid;
  }
}

.gauge-chart {
  margin: auto;
}


.demographics-table.ant-table-wrapper {
  overflow-x:visible !important;
}

.demographics-table .ant-table-content {
  overflow-x: visible !important;
}



.report-card td {
  white-space: normal !important;
}

#fanComments {
  font-size:16px;
}

#fanComments .fan-comment-container {
  display:flex;
  flex-direction: column;
  -ms-flex-direction: column;

  .report-card-body {
    border-radius: 0 0 .25rem .25rem;
  }
}

#fanComments .report-card-body {
  border:1px solid;
  align-self: stretch;
  flex-grow:1;
  padding: 5px 25px; 
  min-height: 30px;
}

@media (max-width:575px) {
  .ant-card-body {
    padding:0.8rem !important;
  }
}

.category-element-chart-data td:nth-child(2) {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  width: 100%;
  max-width: 1px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.chartjs-render-monitor {
  width:100% !important;
}


.dashboard-card {
  border-radius: 80px;
  min-height: 120px;
  min-width: 60px;
}
.dashboard-container {
  border-radius: 15px;
}

.dashboard-score {
  color: white;
  font-size: 26px;
  max-height: 115px;
}

.dashboard-cell {
  font-size: 11px;
   word-wrap: break-word;
}
.dashboard-header {
  font-size: 14px;
}

.dashboard-center-title {
  margin-top: 10px;
  color: dark-grey;
}

.dashboard-description {
  font-weight: bold;
  color: white;
}

.header-collapse  .ant-collapse-header{
  font-weight: bold;
  font-size: 14px;
  color: red;
}

.scorecard-container {
  .entity-comparison-report .ant-table {
    th {
      font-size: 1rem;
      color:white;
      background-color: #474747;
      border-radius: 0 !important;
    }
    th, td {
      padding:.2rem !important;
    }
  }
}

.scorecard-container {
  .event-type-detail-table  {
    thead { 
      tr {      
        background-color: #474747;
        th {
          font-size: 1rem;
          color:white;          
          border-radius: 0 !important;
        }
      }
    }
    tbody {
      background-color: white;
    }
    th, td {
      padding:.2rem !important;
    }
  }
}