
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$print-grid-breakpoints: (xs: 0,
  sm: 1px,
  md: 1px,
  lg: 1px,
  xl: 2px) !default;



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$print-container-max-widths: (sm: 1200px,
  md: 1200px,
  lg: 1200px,
  xl: 1200px) !default;

@mixin display-rules($size-name: '') {
    .print .d#{$size-name}-none         { display: none !important; }
    .print .d#{$size-name}-inline       { display: inline !important; }
    .print .d#{$size-name}-inline-block { display: inline-block !important; }
    .print .d#{$size-name}-block        { display: block !important; }
    .print .d#{$size-name}-table        { display: table !important; }
    .print .d#{$size-name}-table-row    { display: table-row !important; }
    .print .d#{$size-name}-table-cell   { display: table-cell !important; }
    .print .d#{$size-name}-flex         { display: flex !important; }
    .print .d#{$size-name}-inline-flex  { display: inline-flex !important; }
}


// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-print-grid-columns($columns: $grid-columns, $gutters: $grid-gutter-widths, $breakpoints: $print-grid-breakpoints) {
    // Common properties for all breakpoints
    %grid-column {
        position: relative;
        width: 100%;
        min-height: 1px; // Prevent columns from collapsing when empty

        @include make-print-gutters($gutters);
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
        .print .col#{$infix}-#{$i} {
            @extend %grid-column;
        }
        }
        .print .col#{$infix} {
        @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
        // Provide basic `.col-{bp}` classes for equal-width flexbox columns
        .print .col#{$infix} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .print .col#{$infix}-auto {
            flex: 0 0 auto;
            width: auto;
        }

        @for $i from 1 through $columns {
            .print .col#{$infix}-#{$i} {
            @include make-print-col($i, $columns);
            }
        }

        @each $modifier in (pull, push) {
            @for $i from 0 through $columns {
                .print .#{$modifier}#{$infix}-#{$i} {
                @include make-print-col-modifier($modifier, $i, $columns)
            }
            }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
            @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
                .print .offset#{$infix}-#{$i} {
                @include make-print-col-modifier(offset, $i, $columns)
            }
            }
        }
        }
    }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-print-container($gutters: $grid-gutter-widths) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  
    @each $breakpoint in map-keys($gutters) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        padding-right: ($gutter / 2);
        padding-left:  ($gutter / 2);
      }
    }
  }
  
  
  // For each breakpoint, define the maximum width of the container in a media query
@mixin make-print-container-max-widths($max-widths: $print-container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
        width: $container-max-width;
        max-width: 100%;
    }
    }
}

@mixin make-print-gutters($gutters: $grid-gutter-widths) {
    @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        padding-right: ($gutter / 2);
        padding-left:  ($gutter / 2);
    }
    }
}

@mixin make-print-row($gutters: $grid-gutter-widths) {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        margin-right: ($gutter / -2);
        margin-left:  ($gutter / -2);
    }
    }
}

@mixin make-print-col-ready($gutters: $grid-gutter-widths) {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing

    @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        padding-right: ($gutter / 2);
        padding-left:  ($gutter / 2);
    }
    }
}

@mixin make-print-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // width: percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}

@mixin make-print-col-offset($size, $columns: $grid-columns) {
    margin-left: percentage($size / $columns);
}

@mixin make-print-col-push($size, $columns: $grid-columns) {
    left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-print-col-pull($size, $columns: $grid-columns) {
    right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-print-col-modifier($type, $size, $columns) {
    // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
    @if $type == push {
    @include make-print-col-push($size, $columns);
    } @else if $type == pull {
    @include make-print-col-pull($size, $columns);
    } @else if $type == offset {
    @include make-print-col-offset($size, $columns);
    }
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
    .print .container, .print.container {
    @include make-print-container();
    @include make-print-container-max-widths();
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

@if $enable-grid-classes {
    .container-fluid {
    @include make-print-container();
    }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
    .print .row {
    @include make-print-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .print .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    .print {
        max-width:1200px;
        min-width:1200px;
        width:1200px;
        overflow-x:auto;

        &.print-full {
            max-width: none;
            min-width: none;
            width: auto;

            .scorecard-container {
                width:auto !important;
            }

            .demographics-table {
                overflow-x: visible !important;
            }

            .report-page {
                //width:1941px !important;
                width:fit-content !important;
            }
        }

        .no-print {
            display:none !important;
        }

        .only-print {
            display:block !important;
        }

        .ant-collapse-header {
            display:none !important;            
        }

        .ant-collapse-content {
            height:auto !important;
            overflow:visible !important;            
        }
    }

    .only-print {
        display:none !important;
    }


    @include make-print-grid-columns();
}
  
  
// @each $print-breakpoint in map-keys($print-grid-breakpoints) {
//     @media (min-width:1px) {
//       $infix: breakpoint-infix($print-breakpoint, $print-grid-breakpoints);
  
//       .print .d#{$infix}-none         { display: none !important; }
//       .print .d#{$infix}-inline       { display: inline !important; }
//       .print .d#{$infix}-inline-block { display: inline-block !important; }
//       .print .d#{$infix}-block        { display: block !important; }
//       .print .d#{$infix}-table        { display: table !important; }
//       .print .d#{$infix}-table-row    { display: table-row !important; }
//       .print .d#{$infix}-table-cell   { display: table-cell !important; }
//       .print .d#{$infix}-flex         { display: flex !important; }
//       .print .d#{$infix}-inline-flex  { display: inline-flex !important; }
//     }
// }

@media (min-width:0) {    
    @include display-rules('')
}

@media (min-width:1px) {
    @each $sz in ('-sm','-md','-lg') {
        @include display-rules($sz)
    }
}

@media (min-width:2px) {
    @include display-rules('-xl')
}

