.ant-menu { 
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-semibold;
    color: $menu-text;

    &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
            @include background-opacity($brand-primary, 0.1); 

            a {
                color: $brand-primary;
            }
        }
    }

    &:not(.ant-menu-dark) {
        a {
            color: $menu-text;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:hover {
                color: $brand-primary;
            }
        }
    }

    &.ant-menu-dark {
        a {
            color: $menu-dark-text;
        }
    }
}    

.ant-menu-sub {
    font-weight: $font-weight-normal;
}


.ant-menu-horizontal {
    line-height: 2.8125rem;
    border-bottom: 0px;

    > .ant-menu-item-active,
    > .ant-menu-item-open,
    > .ant-menu-item-selected,
    > .ant-menu-submenu-active,
    > .ant-menu-submenu-open,
    > .ant-menu-submenu-selected {
        border-bottom: 0px;
        color: $brand-primary;
    }

    > .ant-menu-item,
    > .ant-menu-submenu {
        border-bottom: 0px;

        &:hover {
            border-bottom: 0px;
            color: $brand-primary;
        }
    }
}

.ant-menu-inline,
.ant-menu-vertical, 
.ant-menu-vertical-left {
    border-right: 0px;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title, 
.ant-menu-vertical-left>.ant-menu-item, .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title, 
.ant-menu-vertical-right>.ant-menu-item, .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title, 
.ant-menu-vertical>.ant-menu-item, 
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    line-height: $menu-spacing-y;
    height: $menu-spacing-y;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left
.ant-menu-vertical-right {

    .ant-menu-item,
    .ant-menu-submenu-title {
        padding-left: 20px; 
    }
}

.ant-menu-item {
    > a {
        color: $menu-text;
    }
}

.ant-menu-item, 
.ant-menu-submenu-title {

    &:active {
        color: $brand-primary;
        @include background-opacity($brand-primary, 0.1); 
    }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical-right  {

    .ant-menu-item {

        &:after {
            border-right: 3px solid $brand-primary;
        }
    }
}

.ant-menu-submenu-inline,
.ant-menu-submenu-vertical-left,
.ant-menu-submenu-vertical-right,
.ant-menu-submenu-vertical {

    >.ant-menu-submenu-title {

        .ant-menu-submenu-arrow {

            &:after,
            &:before {
                content: '';
                position: absolute;
                background: transparent;
                background-image: linear-gradient(to right,$menu-text,$menu-text);
                border-radius: 2px;
            }    
        }

        &:hover {
            .ant-menu-submenu-arrow {

                &:after,
                &:before {
                    background: linear-gradient(to right,$brand-primary,$brand-primary);
                }
            }
        }

        &.ant-menu-submenu-open {

            .ant-menu-submenu-arrow {

                &:after,
                &:before {
                    background: linear-gradient(to right,$brand-primary,$brand-primary);
                }
            }
        }    
    }
}

.ant-menu-dark {
    background-color: $menu-dark-bg;
    color: $menu-dark-text;

    a {
        color: $menu-dark-text;
    }

    &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
            background-color: transparent;

            > a {
                color: $white;
            }
        }
    }

    .ant-menu-item, 
    .ant-menu-item-group-title {
        color: $menu-dark-text;

        > a {
            color: $menu-dark-text;
        }
    }

    .ant-menu-sub {
        background-color: $menu-dark-bg;
        color: $menu-dark-text;

        .ant-menu-submenu-title {

            .ant-menu-submenu-arrow {

                &:after,
                &:before {
                    background:  $white;
                }
            }
        }
    }

    .ant-menu-submenu-title {

        .ant-menu-submenu-arrow {

            &:after,
            &:before {
                background:  $white;
            }
        }
    }

    .ant-menu-inline {
        
        &.ant-menu-sub {
            background-color: $menu-dark-bg-sub;
            box-shadow: none;
        }
    }
}


.ant-menu-item-active, 
.ant-menu-item:hover, 
.ant-menu-submenu-active, 
.ant-menu-submenu-title:hover, 
.ant-menu:not(.ant-menu-inline) 
.ant-menu-submenu-open {
    color: $brand-primary;
}

.ant-menu-light {
    .ant-menu-submenu-open {

        > a {
            color: $brand-primary;

            .ant-menu-submenu-arrow {

                &:after,
                &:before {
                    background-image: linear-gradient(to right,$brand-primary,$brand-primary) !important;
                }
            }
        }
    }
}
