@import 'variables';
@import 'mixins';
@import 'reset';


/*******************/
/*** Components ***/
/*****************/

/*General*/
@import 'components/general/typorgraphy';
@import 'components/general/buttons';
@import 'components/general/icons';
@import 'components/general/scorecards';
@import 'components/general/admin';

/*Layout*/
@import 'components/layout/breakpoint';
@import 'components/layout/grid';
@import 'components/layout/printgrid';

/*Navigation*/
@import 'components/navigation/_affix';
@import 'components/navigation/_breadcrumb';
@import 'components/navigation/dropdown';
@import 'components/navigation/menu';
@import 'components/navigation/pagination';
@import 'components/navigation/page-header';
@import 'components/navigation/steps';

/*Data Entry*/
@import 'components/data-entry/autocomplete';
@import 'components/data-entry/cascader';
@import 'components/data-entry/checkbox';
@import 'components/data-entry/date-picker';
@import 'components/data-entry/form';
@import 'components/data-entry/input';
@import 'components/data-entry/mention';
@import 'components/data-entry/radio';
@import 'components/data-entry/rate';
@import 'components/data-entry/select';
@import 'components/data-entry/slider';
@import 'components/data-entry/switch';
@import 'components/data-entry/time-picker';
@import 'components/data-entry/transfer';
@import 'components/data-entry/tree-select';
@import 'components/data-entry/upload';

/*Data Display*/
@import 'components/data-display/avatar';
@import 'components/data-display/badge';
@import 'components/data-display/calendar';
@import 'components/data-display/card';
@import 'components/data-display/carousel';
@import 'components/data-display/collapse';
@import 'components/data-display/comment';
@import 'components/data-display/descriptions';
@import 'components/data-display/list';
@import 'components/data-display/popover';
@import 'components/data-display/statistic';
@import 'components/data-display/table';
@import 'components/data-display/tabs';
@import 'components/data-display/tag';
@import 'components/data-display/timeline';
@import 'components/data-display/tooltip';
@import 'components/data-display/tree';

/*Feedback*/
@import 'components/feedback/alert';
@import 'components/feedback/drawer';
@import 'components/feedback/message';
@import 'components/feedback/modal';
@import 'components/feedback/notifications';
@import 'components/feedback/progress';
@import 'components/feedback/spin';

/*Other*/
@import 'components/others/anchor';
@import 'components/others/back-to-top';
@import 'components/others/divider';

/******************/
/*** Template ****/
/****************/
@import 'template/header.scss';
@import 'template/layout.scss';
@import 'template/side-nav.scss';
@import 'template/footer.scss';

/*****************/
/***** Apps *****/
/***************/
@import 'apps/chat.scss';
@import 'apps/file-manager.scss';
@import 'apps/mail.scss';
@import 'apps/e-commerce.scss';

/*****************/
/*** Plugins ****/
/***************/
//@import 'plugins/chartist.scss';
@import 'plugins/quill.scss';

/*******************/
/*** Utilities ****/
/*****************/
@import 'utilities/utilities-bs.scss';
@import 'utilities/utilities-enlink.scss';


/*******************/
/****** Demo ******/
/*****************/

@import 'demo/demo.scss';


/*******************/
/***** custom *****/
/*****************/

@media (max-width:767px) {
    .ant-select {
        margin-bottom:10px;
    }
}

@media (max-width:575px) {
    .container {
        width:90%;
    }
}

.blockui-overlay {
    visibility: hidden;

    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 1000;
    opacity:0;

    &:before{
        opacity:0;
        content: '';
    }

    &.show {
        visibility: visible;
        background: linear-gradient(-45deg,  #333, #669ff5);
        opacity: .95;
        transition: opacity 0.3s;


        &:before{
            background: linear-gradient(-45deg,#669ff5, #333);
            opacity:.98;
            transition: opacity 6s;
            z-index:-1000;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }



}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.report-page, .inventory-report-container {
    .ant-collapse-header {
        background-color:#434343;
        color:white !important;
        font-size:16px;
    }

    .ant-collapse-content-box {
        padding-left:0;
        padding-right:0;
    }    
}

.collapse-header-tooltip {
    .ant-tooltip-inner {
        background-color: #252525;
    }
}

.col-auto {
    padding-left:9px;
    padding-right:9px;
}

.realtime-feedback-card.ant-card {
    .ant-card-head {
        background-color: #3f87f5;
        color:white;
    }
    .ant-card-extra {
        color:white;
    }
}

.season-analysis-card.ant-card {
    > .ant-card-head {
        background-color: #3f87f5;
        color:white;
    }
    > .ant-card-body {
        background-color:#fcfcfc;
    }
    
}

.print {
    .demographics-container {
        overflow:visible !important;
        max-height: none !important;
        height:auto !important;
    }
}

.demographics-container {
    display:block;
    max-height:calc(100vh - 130px);
    overflow-y: auto;

    thead {
        position:sticky;
        top:0;
        z-index: 3;
        background-color:rgb(243, 243, 243);

        th.sticky-1 {
            position:sticky;
            z-index: 2;
            left:0;
            background-color:rgb(243, 243, 243);
        }
        th.sticky-2 {
            position:sticky;
            z-index: 2;
            left:70px;
            background-color:rgb(243, 243, 243);
        }
        
    }

    tbody {
    
    th {
            position:sticky;
            left:70px;
            z-index: 1;
            font-weight:normal;
            text-align:center;
            background-color:rgb(243, 243, 243);
            padding: 8px;

            &:first-child {
                left:0;
            }
        }
    }

    .sticky-3 {
        position:sticky;
        z-index: 2;
        left:70px;
        background-color:rgb(243, 243, 243);
    }
    .sticky-4 {
        position:sticky;
        z-index: 2;
        left:100px;
        background-color:rgb(243, 243, 243);
    }
}

.ng-checkbox-group-w-100 label.ant-checkbox-wrapper {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.icon-hover-border {
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 3px;

    &:hover {
        border-color: inherit;
    }
}

.scrollable-nztable {
    overflow-y:auto;

    thead th {
        position: sticky !important;
        top:0;
        z-index: 1;
    }

    thead tr:not(.report-card-sub-header) th {
        background-color: white;
    }

    thead tr.report-card-sub-header th {
        background-color:#474747;

        &:hover {
            background-color: #474747 !important;
        }
    }
}

@media print {
    .scrollable-nztable {
        overflow:visible;

        thead th {
            position: static !important;
            
        }
    }
}

.scrollable-nztable-thead {
    overflow-y:auto;

    thead {
        position: sticky !important;
        top:0;
        z-index: 1;
    }

    thead tr:not(.report-card-sub-header) th {
        background-color: white;
    }

    thead tr.report-card-sub-header th {
        background-color:#474747;

        &:hover {
            background-color: #474747 !important;
        }
    }
}

@media print {
    .scrollable-nztable-thead {
        overflow:visible;

        thead {
            position: static !important;
            
        }
    }
}

.strong-border {
    .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
        border-bottom-color: #cdd2d9;
    }

    .ant-table-tbody > tr.weak-border > td {
        border-bottom-color: #edf2f9;
    }
}

.alternate-shading:nth-child(2n-1) {
    background-color: #ececec;
}

.click-card-separator {
    margin:0.8rem; 
    border:1px solid #53535f;

    &:last-child {
        border:none;
        height:0.1rem;
    }
}