.full-width-date-select {
  .ant-calendar-picker {
    width: 100% !important;
  }
}


.ant-upload {
  .avatar {
    max-height: 130px;
  }
}
