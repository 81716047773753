.ant-alert {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $alert-text-color;
    position: relative;
    padding: $alert-padding-y $alert-padding-x $alert-padding-y $alert-padding-w-icon;
    border-radius: $alert-border-radius;
}

@each $alert in $alert-loop {

    .#{nth($alert, 1)} {
        background-color: #{nth($alert, 2)};
        border: 1px solid #{nth($alert, 4)};

        .ant-alert-icon {
            color: #{nth($alert, 3)};
        }
    }
}   

.ant-alert-with-description {
    padding: $alert-padding-y $alert-padding-y $alert-padding-y 64px;
    position: relative;
    border-radius: 4px;
    color: $alert-text-color;
    line-height: $line-height-base;

    .ant-alert-message {
        font-size: $font-size-lg;
        color: $gray-dark;
        margin-bottom: 5px;
    }    
}

.ant-alert-close-icon {

    .anticon-cross {
        color: $alert-text-color;

        &:hover {
            color: $gray-dark;
        }
    }
}



